$vertical-padding: 6px
$horizontal-padding: 12px
$vertical-margin: 15px
$horizontal-margin: 30px
$primary-color: #6200EE
$secondary-color: #e4e4e4
$secondary-color-dark: #1b1b1b
$danger-color: rgb(255,59,48)
$success-color: rgb(52,199,89)
$border-radius: 8px
$border-radius-round: 50px
$subtle-opacity: 0.7
$drop-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
$font-size: 16px
$font-size-small: 14px
$font-weight: 400
$font-weight-strong: 600
$font-weight-stronger: 700
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
$font-family-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
$pc: 1024px
$tablet: 720px
$phone: 320px

@mixin responsive($media)
  @if $media == phone
    @media only screen and (max-width: $tablet - 1)
      @content

  @else if $media == tablet
    @media only screen and (min-width: $tablet - 1) and (max-width: $pc)
      @content

  @else if $media == pc
    @media only screen and (min-width: $pc + 1) and (min-width: $pc)
      @content

  @else if $media == pc_tablet
    @media only screen and (min-width: $tablet - 1)
      @content

body
  margin: 0
  font-family: $font-family
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  @media (prefers-color-scheme: dark)
    background: black
    color: white

code
  font-family: $font-family-code

.h1, .h2, .h3
  margin: 0
  padding: 0
  font-size: $font-size
  font-weight: $font-weight-stronger
  flex-grow: 1
  font-size: 34px
  margin: $vertical-margin 0 $vertical-margin * 2

.h1
  font-size: 34px

.h2
  font-size: 28px

.h3
  font-size: 24px

.app
  padding: $vertical-margin $horizontal-margin

.button
  padding: $vertical-padding $horizontal-padding
  margin: 0 $horizontal-padding $horizontal-padding 0
  font-family: $font-family
  font-size: $font-size
  font-weight: $font-weight-strong
  outline: none
  border: none
  border-radius: $border-radius-round
  background: none
  backdrop-filter: blur(5px)
  white-space: nowrap
  cursor: pointer
  text-align: center

  @media (prefers-color-scheme: light)
    color: #000
    background: #fff
    box-shadow: $drop-shadow, 0 0 0 1px $secondary-color

  @media (prefers-color-scheme: dark)
    color: #fff
    background: #000
    box-shadow: $drop-shadow, 0 0 0 1px $secondary-color-dark

  &.selected
    color: #fff !important
    background: $primary-color
    box-shadow: $drop-shadow, 0 0 0 1px $primary-color
    font-weight: $font-weight-strong

  &:hover
    color: #fff
    background: lighten($primary-color, 10%)
    box-shadow: $drop-shadow, 0 0 0 1px lighten($primary-color, 10%)

  &:active
    color: #fff
    background: darken($primary-color, 10%)
    box-shadow: $drop-shadow, 0 0 0 1px darken($primary-color, 10%)

  &:disabled
    background: desaturate($primary-color, 100%)
    box-shadow: $drop-shadow, 0 0 0 1px desaturate($primary-color, 100%)

  &:last-child
    margin-right: 0

  &.danger
    color: #fff
    background: $danger-color
    box-shadow: $drop-shadow, 0 0 0 1px $danger-color

    &:hover
      color: #fff
      background: lighten($danger-color, 10%)
      box-shadow: $drop-shadow, 0 0 0 1px lighten($danger-color, 10%)

    &:active
      color: #fff
      background: darken($danger-color, 10%)
      box-shadow: $drop-shadow, 0 0 0 1px darken($danger-color, 10%)

  &.circle
    border-radius: 100%
    height: 36px
    width: 36px

.title
  margin-bottom: 0

.description
  margin: 0 0 $vertical-margin 0
  font-size: $font-size-small
  font-weight: $font-weight-strong
  font-style: italic
  opacity: $subtle-opacity

.version
  font-size: $font-size-small
  font-weight: $font-weight-strong

.select-cinema
  position: relative
  width: min-content
  max-width: 100%

  select
    border: none
    outline: none
    cursor: pointer
    margin-top: 0
    appearance: none
    max-width: 100%
    padding-right: 16px

    @media (prefers-color-scheme: light)
      background: #fff
      color: #000

    @media (prefers-color-scheme: dark)
      background: #000
      color: #fff

  &::after
    content: "▾"
    font-size: 32px
    opacity: $subtle-opacity
    position: absolute
    top: -5px
    right: 0

.cinemas
  display: flex
  flex-wrap: wrap

.movies
  display: flex
  flex-wrap: wrap

  .movie
    display: flex
    flex-direction: column
    position: relative
    border-radius: $border-radius

    @media (prefers-color-scheme: light)
      box-shadow: $drop-shadow, 0 0 0 1px $secondary-color

    @media (prefers-color-scheme: dark)
      box-shadow: $drop-shadow, 0 0 0 1px $secondary-color-dark

    @include responsive(pc)
      margin: 0 $horizontal-padding $horizontal-padding 0
      width: calc(100% / 8 - ($horizontal-padding * 7 / 8))

      &:nth-child(8n)
        margin-right: 0

    @include responsive(tablet)
      margin: 0 $horizontal-padding $horizontal-padding 0
      width: calc(100% / 6 - ($horizontal-padding * 5 / 6))

      &:nth-child(6n)
        margin-right: 0

    @include responsive(phone)
      margin: 0 $horizontal-padding $horizontal-padding 0
      width: calc(100% / 2 - ($horizontal-padding / 2))

      &:nth-child(2n)
        margin-right: 0

    .poster
      display: flex
      width: 100%
      height: 100%
      border-radius: $border-radius

    .name, .vote
      display: flex
      padding: $vertical-padding $horizontal-padding
      position: absolute
      color: white
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5)
      font-size: $font-size-small
      font-weight: $font-weight-strong
      flex-grow: 1
      
      visibility: hidden

    .name
      bottom: 0
      border-top-right-radius: $border-radius - 1
      border-bottom-left-radius: $border-radius - 1
      background: $primary-color

    .vote
      top: 0
      right: 0
      border-top-right-radius: $border-radius - 1
      border-bottom-left-radius: $border-radius - 1
      background: $success-color

    &:hover
      box-shadow: 0 0 0 3px $primary-color
      cursor: pointer

      .name, .vote
        visibility: visible

.movie-details
  position: fixed
  overflow: auto
  backdrop-filter: blur(15px)
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  flex-direction: column
  padding: $vertical-margin $horizontal-margin
  z-index: 100

  @media (prefers-color-scheme: light)
    background: rgba(#fff, 0.9)

  @media (prefers-color-scheme: dark)
    background: rgba(#000, 0.9)

  .header
    .title
      flex-grow: 1
      margin-bottom: $vertical-margin * 2

      .name
        font-size: 34px
        margin-bottom: 0

      .special-edition, .year
        opacity: $subtle-opacity
        margin-left: $horizontal-padding
        font-weight: $font-weight
      
      .tagline
        margin: 0 0 $vertical-margin 0
        font-size: $font-size-small
        font-weight: $font-weight-strong
        font-style: italic
        opacity: $subtle-opacity

    .buttons
      position: absolute
      display: flex
      flex-wrap: wrap
      top: 0
      right: 0

  .content
    display: flex

    @include responsive(phone)
      flex-direction: column

    .sidebar

      @include responsive(pc)
        margin-right: $horizontal-margin
        width: min-content

      @include responsive(tablet)
        margin-right: $horizontal-margin
        width: min-content

      .poster
        border-radius: $border-radius

        @media (prefers-color-scheme: light)
          box-shadow: $drop-shadow, 0 0 0 1px $secondary-color

        @media (prefers-color-scheme: dark)
          box-shadow: $drop-shadow, 0 0 0 1px $secondary-color-dark

        @include responsive(pc)
          width: 320px

        @include responsive(tablet)
          width: 240px

        @include responsive(phone)
          width: 100%

      .links, .sessions
        display: flex
        flex-direction: column
        align-items: center
        margin-bottom: $vertical-margin

        .heading
          margin-top: $vertical-margin

        .buttons
          width: 100%

        .button
          width: 100%

    .detail
      margin-bottom: $vertical-margin

      & > span
        opacity: $subtle-opacity

      .cast-list
        display: flex
        flex-wrap: wrap

        .cast
          display: flex
          flex-direction: column
          margin: 0 $horizontal-margin $horizontal-margin 0
          width: 100px

          @include responsive(phone)
            width: calc(100% / 2 - ($horizontal-margin / 2))

            &:nth-child(2n)
              margin-right: 0

          img
            width: 100%
            border-radius: $border-radius

            @media (prefers-color-scheme: light)
              box-shadow: $drop-shadow, 0 0 0 1px $secondary-color

            @media (prefers-color-scheme: dark)
              box-shadow: $drop-shadow, 0 0 0 1px $secondary-color-dark

          .cast-name
            font-weight: $font-weight-strong

          .character
            //font-style: italic
            font-size: $font-size-small
            opacity: $subtle-opacity

  .heading
    margin: 0 0 $vertical-padding 0
    padding: 0
    font-size: $font-size
    font-weight: $font-weight-stronger

.floating-buttons
  position: fixed
  top: $vertical-margin
  right: $horizontal-margin
  z-index: 200

.video-responsive
  margin-top: $vertical-margin * 2

  iframe
    width: 720px
    height: 405px
    border-radius: $border-radius

    @media (prefers-color-scheme: light)
      box-shadow: $drop-shadow, 0 0 0 1px $secondary-color

    @media (prefers-color-scheme: dark)
      box-shadow: $drop-shadow, 0 0 0 1px $secondary-color-dark

    @include responsive(phone)
      width: 100%
      height: 270px

.loader
  position: fixed
  font-size: 24px
  font-weight: $font-weight-strong
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  backdrop-filter: blur(15px)

  @media (prefers-color-scheme: light)
    background: rgba(255,255,255,0.9)

  @media (prefers-color-scheme: dark)
    background: rgba(0,0,0,0.9)